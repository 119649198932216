


































































































































































































































































































































































































import { Component, Prop, Ref, Vue } from 'vue-property-decorator'
// @ts-ignore
import { CIQ } from 'chartiq/js/componentUI'
import { getCustomConfig } from './resources' // ChartIQ library resources
@Component({})
export default class AdvancedChartComponent extends Vue {
	@Prop() config: any
	@Prop({ type: Function, default: ({}) => {} }) chartInitialized!: Function
	@Ref('container') container!: HTMLElement
	stx: CIQ.ChartEngine | undefined
	mounted() {
		const config = this.config || getCustomConfig()
		const container = this.container
		portalizeContextDialogs(container)
		// Delay the call to createChartAndUI so any other AdvancedChart components on the page
		// have a chance to call portalizeContextDialogs
		window.setTimeout(() => {
			const uiContext = this.createChartAndUI(config)
			this.stx = uiContext.stx
			console.log(this.stx.chart)
			console.log(container)
			console.log(config)
			this.chartInitialized({ chartEngine: uiContext.stx, uiContext, config })
		}, 0)
	}
	beforeDestroy() {
		// Destroy the ChartEngine instance when unloading the component.
		// This will stop internal processes such as quotefeed polling.
		this.stx?.destroy()
	}
	createChartAndUI(config: any) {
		console.log(config)
		const container = this.container
		const chart = new CIQ.UI.Chart()
		const uiContext = chart.createChartAndUI({ container, config })
		return uiContext
	}
}
/**
 * For applications that have more then one chart, keep single dialog of the same type
 * and move it outside context node to be shared by all chart components
 */
function portalizeContextDialogs(container: HTMLElement) {
	container.querySelectorAll('cq-dialog').forEach(dialog => {
		dialog.remove()
		if (!dialogPortalized(dialog)) {
			document.body.appendChild(dialog)
		}
	})
}
function dialogPortalized(el: Element) {
	if (!el.firstChild) {
		throw new Error('Element has no children')
	}
	const tag = el.firstChild.nodeName.toLowerCase()
	const result = Array.from(document.querySelectorAll(tag)).some(
		el => !el.closest('cq-context')
	)
	return result
}
